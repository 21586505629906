import React, { Component } from "react";
import Web3 from "web3";
import "./App.css";
// import Color from '../abis/Color.json'
//import Cryptopopz from '../abis/Cryptopopz.json'

let Cryptopopz = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "minter",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "uri",
        type: "string",
      },
    ],
    name: "PopCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "howmany",
        type: "uint256",
      },
    ],
    name: "bulkMint",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "burn",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint32",
        name: "newprice",
        type: "uint32",
      },
    ],
    name: "changePrice",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "createCollectible",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string[]",
        name: "_popzjson",
        type: "string[]",
      },
    ],
    name: "fillPopz",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "getApproved",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
    ],
    name: "isApprovedForAll",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isOwner",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "ownerOf",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "price",
    outputs: [
      {
        internalType: "uint32",
        name: "",
        type: "uint32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "_data",
        type: "bytes",
      },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "operator",
        type: "address",
      },
      {
        internalType: "bool",
        name: "approved",
        type: "bool",
      },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "soldOut",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "tokenCounter",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "index",
        type: "uint256",
      },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "tokenURI",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "tokenId2",
        type: "uint256",
      },
    ],
    name: "trade",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

class App extends Component {
  async componentWillMount() {
    // maybe not needed
    this.setState({
      contractDeploymentData: Cryptopopz,
    });

    this.setState({
      accounts: [],
    });

    this.setState({
      myTokens: [],
    });

    this.setState({
      myTokensTotal: 0,
    });

    this.setState({
      selectedTokens: [],
    });

    this.setState({
      isOwner: false,
    });

    this.setState({
      rarity: false,
    });

    this.setState({
      showCustomGas: false,
    });

    this.setState({
      networkId: null,
    });

    const ethEnabled = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.send("eth_requestAccounts");
        } catch {}
        window.web3 = new Web3(window.ethereum);
        return true;
      }

      return false;
    };

    let networkPresent = await ethEnabled();

    if (networkPresent) {
      // must listen or subscribe to account change
      this.checkNetworkAndAccounts();
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async checkNetworkAndAccounts() {
    console.log("checking network and accounts");

    const networkId = await window.web3.eth.net.getId();

    // is ploygon or ethereum
    if (networkId === 1 || networkId === 137) {
      // network has changed
      if (networkId !== this.state.networkId) {
        console.log("network changed");
        this.setState({ networkId });
        this.loadContractData();
        this.checkGasPrice();
        this.loadPriceData();
      }

      let accounts = await window.web3.eth.getAccounts();

      if (accounts[0] !== this.state.account) {
        console.log("account changed");
        this.setState({ account: accounts[0] });
        this.loadAccountData();
      }

      setTimeout(() => {
        this.checkNetworkAndAccounts();
      }, 3000);
    } else {
      window.alert(
        "your browser must be connected to ethereum or polygon blockchain!"
      );
    }
  }

  async loadContractData() {
    console.log("loading contract data");
    // const networkData = this.state.contractDeploymentData.networks[networkId]
    // get contract
    //const abi = this.state.contractDeploymentData.abi

    const addressArray = [
      "0xD22D9c54b3491d80EcdDce5eB3bf4EB687BD1C7A",
      "0x388F4f67FC67076F80C4C7F15082CF48DD80C34f",
    ];
    const abi = Cryptopopz;

    if (this.state.networkId === 1) {
      let address = addressArray[0];
      this.setState({ address });
      const contract = new window.web3.eth.Contract(abi, address);
      this.setState({ contract });
      // get owner address

      let owner = await contract.methods.owner().call();
      this.setState({ owner });
    } else if (this.state.networkId === 137) {
      let address = addressArray[1];
      this.setState({ address });
      const contract = new window.web3.eth.Contract(abi, address);
      this.setState({ contract });
      // get owner address

      let owner = await contract.methods.owner().call();
      this.setState({ owner });
    }
  }

  async loadPriceData() {
    console.log("loading price data");
    let totalSupply = 0;
    let soldOut = true;
    let fee = 8000000;

    // get total number of tokens
    totalSupply = await this.state.contract.methods.totalSupply().call();
    this.setState({ totalSupply });

    // sold out?
    soldOut = await this.state.contract.methods.soldOut().call();
    this.setState({ soldOut });

    // get token price in gwei
    fee = await this.state.contract.methods.price().call();
    const amountToSend = window.web3.utils.toWei(fee, "gwei");
    this.setState({ amountToSend });

    // check gas price was here

    if (!this.state.soldOut) {
      // estimate gas fees
      let gasEstimateAccount = false ? this.state.account : this.state.owner;

      // this should have a default value
      const gasEstimate = await this.state.contract.methods
        .createCollectible()
        .estimateGas({
          from: gasEstimateAccount,
          value: this.state.amountToSend,
        });
      /* using the promise
      contract.methods.createCollectible().estimateGas({from: this.state.account, value: this.state.amountToSend  })
      .then(function(gasAmount){
          console.log('her')
          this.setState({ gasAmount })
      })
      .catch(function(error){
          console.log('der')
      });
      */
      this.setState({ gasEstimate });

      let price =
        parseInt(this.state.amountToSend) +
        parseInt(this.state.gasEstimate) *
          parseInt(this.state.gasPriceEstimate);
      price = window.web3.utils.fromWei(price.toString(), "ether");
      this.setState({ price });

      //await this.checkGasPrice();
    }
  }

  async checkGasPrice() {
    console.log("checking gas price");
    // wish I could subscribe!!!!!

    // get gas price in wei and convert to gwei
    let gasPriceEstimate = await window.web3.eth.getGasPrice();
    // console.log(gasPriceEstimate)
    // gasPriceEstimate = window.web3.utils.fromWei(gasPriceEstimate, "gwei")
    this.setState({ gasPriceEstimate });
    let gasPriceEstimateinGwei = window.web3.utils.fromWei(
      gasPriceEstimate,
      "gwei"
    );
    this.setState({ gasPriceEstimateinGwei });
    // calculate total cost of mint

    /* as promise
	window.web3.eth.getGasPrice()
	.then((gasPriceEstimate) => {
		gasPriceEstimate = window.web3.utils.fromWei(gasPriceEstimate, "gwei")
		this.setState({ gasPriceEstimate })
		setTimeout(()=>{this.checkGasPrice()}, 30000)
	})
	.catch(function(error){
		console.log(error)
	})
	*/
  }

  async loadAccountData() {
    console.log("loading account data");
    //reset account data
    this.setState({
      myTokens: [],
    });

    this.setState({
      myTokensTotal: 0,
    });

    this.setState({
      selectedTokens: [],
    });

    this.setState({
      isOwner: false,
    });

    // get the current accounts data
    if (this.state.account && this.state.contract) {
      let contract = this.state.contract;

      let myTokensTotal = await contract.methods
        .balanceOf(this.state.account)
        .call();
      myTokensTotal = myTokensTotal.toString();
      this.setState({ myTokensTotal });

      for (var i = 0; i < myTokensTotal; i++) {
        let someTokenIndex = await contract.methods
          .tokenOfOwnerByIndex(this.state.account, i)
          .call();
        let someTokenURI = await contract.methods
          .tokenURI(someTokenIndex)
          .call();
        let tokenCID = someTokenURI.split("://")[1];

        fetch(`https://ipfs.io/ipfs/${tokenCID}`)
          .then((response) => response.json())
          .then((jsonData) => {
            let jsonWithTokenId = jsonData;
            jsonWithTokenId.id = someTokenIndex;
            this.setState({
              myTokens: [...this.state.myTokens, jsonWithTokenId],
            });
          })
          .catch((error) => {
            // handle your errors here
            console.error(error);
          });
      }

      // check  owner
      let isOwner = this.state.account === this.state.owner;
      this.setState({ isOwner });

      if (isOwner) {
        // get contract balance in wei and convert to ether
        let contractBalance = await window.web3.eth.getBalance(
          this.state.address
        );
        contractBalance = window.web3.utils.fromWei(contractBalance, "ether");
        this.setState({ contractBalance });
      }
    }
  }

  mint = async () => {
    if (this.state.account === undefined || this.state.account == null) {
      try {
        await window.ethereum.send("eth_requestAccounts");
        let accounts = await window.web3.eth.getAccounts();
        this.setState({ account: accounts[0] });
        this.loadAccountData();
      } catch {
        return false;
      }
    }

    // sold out?
    let soldOut = await this.state.contract.methods.soldOut().call();
    this.setState({ soldOut });
    if (this.state.soldOut) {
      window.alert("Sold Out!");
      return false;
    }

    // this.state.contract.methods.mint(color).send({ from: this.state.account })
    let config = { from: this.state.account, value: this.state.amountToSend };

    if (this.state.userGasPrice) {
      config.gasPrice = window.web3.utils.toWei(
        this.state.userGasPrice,
        "gwei"
      );
    }

    this.setState({ pendingTransaction: true });

    this.state.contract.methods
      .createCollectible()
      .send(config)
      .on("receipt", (receipt) => {
        //      console.log(receipt)
        /*
      this.setState({
        colors: [...this.state.colors, color]
      })
      */
        this.setState({ pendingTransaction: false });
        this.loadAccountData();
      })
      .on("error", () => {
        this.setState({ pendingTransaction: false });
      });
  };

  trade = async () => {
    // sold out?
    let soldOut = await this.state.contract.methods.soldOut().call();
    this.setState({ soldOut });
    if (this.state.soldOut) {
      window.alert("Sold Out!");
      return false;
    }

    // this.state.contract.methods.mint(color).send({ from: this.state.account })
    if (this.state.selectedTokens.length === 2) {
      if (window.confirm("Burn these two tokens, and mint one new one?")) {
        let config = { from: this.state.account };

        if (this.state.userGasPrice) {
          config.gasPrice = window.web3.utils.toWei(
            this.state.userGasPrice,
            "gwei"
          );
        }

        this.setState({ pendingTransaction: true });

        this.state.contract.methods
          .trade(
            this.state.selectedTokens[0].id,
            this.state.selectedTokens[1].id
          )
          .send(config)
          .on("receipt", (receipt) => {
            this.setState({ pendingTransaction: false });
            this.loadAccountData();
          })
          .on("error", () => {
            this.setState({ pendingTransaction: false });
          });
      }
    } else {
      window.alert("Please select two tokens to burn");
    }
  };

  refill = (newProducts) => {
    let config = { from: this.state.account };

    if (this.state.userGasPrice) {
      config.gasPrice = window.web3.utils.toWei(
        this.state.userGasPrice,
        "gwei"
      );
    }

    this.state.contract.methods
      .fillPopz(newProducts.split(","))
      .send(config)
      .once("receipt", (receipt) => {
        console.log(receipt);
      });
  };

  selectToken = (token) => {
    if (this.state.selectedTokens.includes(token)) {
      // toggle selection
      let selectedTokens = this.state.selectedTokens;
      selectedTokens.splice(selectedTokens.indexOf(token), 1);
      this.setState({
        selectedTokens: selectedTokens,
      });
    } else if (this.state.selectedTokens.length < 2) {
      // add to selection
      this.setState({
        selectedTokens: [...this.state.selectedTokens, token],
      });
    } else {
    }
  };

  changePrice = (price) => {
    let config = { from: this.state.account };

    if (this.state.userGasPrice) {
      config.gasPrice = window.web3.utils.toWei(
        this.state.userGasPrice,
        "gwei"
      );
    }

    this.state.contract.methods
      .changePrice(price)
      .send(config)
      .once("receipt", (receipt) => {
        console.log(receipt);
      });
  };

  bulkMint = (howmany) => {
    let config = { from: this.state.account };

    if (this.state.userGasPrice) {
      config.gasPrice = window.web3.utils.toWei(
        this.state.userGasPrice,
        "gwei"
      );
    }

    this.state.contract.methods
      .bulkMint(howmany)
      .send(config)
      .once("receipt", (receipt) => {
        console.log(receipt);
      });
  };

  overrideGasPrice = (userGasPrice) => {
    this.setState({ userGasPrice });
  };

  constructor(props) {
    super(props);
    this.state = {
      account: "",
      contract: null,
      totalSupply: 0,
      selectedTokens: [],
    };
  }

  render() {
    return (
      <div id="vending-machine" className="">
        <div id="marquee" className="">
          <span
            id="network-name"
            className={`text-center price ${
              this.state.networkId === 1 ? "" : "hidden"
            }`}
          >
            Ethereum
          </span>
          <span
            id="network-name"
            className={`text-center price ${
              this.state.networkId === 137 ? "" : "hidden"
            }`}
          >
            Polygon
          </span>
        </div>
        <div id="controls" className="">
          <div id="buttons" className="">
            <div id="button-row" className="">
              <div
                id="button1"
                className="vend-button text-center align-middle"
                onClick={this.mint}
                disabled={this.state.soldOut || !this.state.account}
              >
                {this.state.soldOut ? "" : ""}
              </div>
              <div
                id="button2"
                className="vend-button text-center"
                onClick={this.mint}
                disabled={this.state.soldOut || !this.state.account}
              >
                {this.state.soldOut ? "" : ""}
              </div>
            </div>
            <div id="button-row2" className="">
              <div
                id="button3"
                className="vend-button text-center"
                onClick={this.mint}
                disabled={this.state.soldOut || !this.state.account}
              >
                {this.state.soldOut ? "" : ""}
              </div>
              <div
                id="button4"
                className="vend-button text-center"
                onClick={this.mint}
                disabled={this.state.soldOut || !this.state.account}
              >
                {this.state.soldOut ? "" : ""}
              </div>
            </div>
          </div>
          <div id="price-area" className="">
            <div
              className={`text-center price ${
                this.state.pendingTransaction ? "hidden" : ""
              }`}
            >
              {!this.state.soldOut && this.state.networkId === 1
                ? parseFloat(this.state.price).toFixed(5) + " Eth"
                : ""}
              {!this.state.soldOut && this.state.networkId === 137
                ? parseFloat(this.state.price).toFixed(5) + " Matic"
                : ""}
              {this.state.soldOut ? "Sold Out" : ""}
            </div>
            <div
              className={`text-center price ${
                this.state.pendingTransaction ? "" : "hidden"
              }`}
            >
              {this.state.pendingTransaction ? "Transaction Pending" : ""}
            </div>
            <div id="show-gas-override-area">
              <a
                id="show-gas-override"
                href="#custom-gas-area"
                onClick={() =>
                  this.setState({ showCustomGas: !this.state.showCustomGas })
                }
              >
                Override Gas Price
              </a>
            </div>
          </div>
        </div>
        <div id="primary-area" className="">
          <div id="vending-area" className="">
            <div id="total-minted">Total Minted: {this.state.totalSupply}</div>
            <a
              id="rarity"
              href="#rarity-report"
              onClick={() => this.setState({ rarity: !this.state.rarity })}
            >
              Rarity Report
            </a>
            <div
              id="custom-gas-area"
              className={`${this.state.showCustomGas ? "" : "hidden"}`}
            >
              Gas Price:{" "}
              {this.state.userGasPrice
                ? this.state.userGasPrice
                : parseFloat(this.state.gasPriceEstimateinGwei).toFixed(5)}{" "}
              Gwei
              <form
                id="gas-form"
                className="form-inline"
                onSubmit={(event) => {
                  event.preventDefault();
                  const userGasPrice = this.userGasPrice.value;
                  this.overrideGasPrice(userGasPrice);
                }}
              >
                <input
                  id="gas-input"
                  type="text"
                  className="form-control"
                  placeholder=""
                  ref={(input) => {
                    this.userGasPrice = input;
                  }}
                />
                <input
                  id="gas-button"
                  type="submit"
                  className="btn btn-primary"
                  value="Override Gas Price(gwei)"
                />
              </form>
            </div>
          </div>
          <div id="token-area" className="">
            <div id="your-popz">Your Popz: {this.state.myTokensTotal}</div>
            <button
              id="exchange"
              className="btn btn-block btn-danger"
              onClick={() => this.trade()}
              disabled={
                this.state.soldOut ||
                !this.state.account ||
                this.state.myTokens < 2
              }
            >
              {!this.state.soldOut && this.state.myTokens.length >= 2
                ? "EXCHANGE"
                : ""}
              {this.state.soldOut ? "Sold Out" : ""}
              {this.state.myTokens.length < 2 && !this.state.soldOut
                ? "You must burn two tokens to exchange"
                : ""}
            </button>
            {this.state.myTokens.map((token, key) => {
              let tokenImageCID = token.image.split("://")[1];
              return (
                <div
                  key={key}
                  className={`token ${
                    this.state.selectedTokens.includes(token)
                      ? "selectedToken"
                      : ""
                  }`}
                  onClick={() => this.selectToken(token)}
                >
                  <img
                    className="token-image"
                    src={`https://ipfs.io/ipfs/${tokenImageCID}`}
                    alt="token"
                  />
                  <a href={`https://ipfs.io/ipfs/${tokenImageCID}`}>View</a>
                </div>
              );
            })}
          </div>
        </div>
        <div id="footer" className="">
          <div
            id="owner-area"
            className={`${this.state.isOwner ? "" : "hidden"} p-0`}
          >
            Balance: {this.state.contractBalance}
            <form
              className="form-inline"
              onSubmit={(event) => {
                event.preventDefault();
                const newProducts = this.products.value;
                this.refill(newProducts);
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder=""
                ref={(input) => {
                  this.products = input;
                }}
              />
              <input type="submit" className="btn btn-primary" value="Refill" />
            </form>
            <form
              className="form-inline"
              onSubmit={(event) => {
                event.preventDefault();
                const price = this.price.value;
                this.changePrice(price);
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder=""
                ref={(input) => {
                  this.price = input;
                }}
              />
              <input
                type="submit"
                className="btn btn-primary"
                value="Change Price(in gwei)"
              />
            </form>
            <form
              className="form-inline"
              onSubmit={(event) => {
                event.preventDefault();
                const howmany = this.howmany.value;
                this.bulkMint(howmany);
              }}
            >
              <input
                type="text"
                className="form-control"
                placeholder=""
                ref={(input) => {
                  this.howmany = input;
                }}
              />
              <input
                type="submit"
                className="btn btn-primary"
                value="Bulk Mint"
              />
            </form>
          </div>
          <div
            id="rarity-report"
            className={`${this.state.rarity ? "" : "hidden"} p-0`}
          >
            <h2>Base Flavors</h2>
            <ul>
              <li>Cola 57%</li>
              <li>Drepper 15%</li>
              <li>LemLime 13%</li>
              <li>GingAle 6%</li>
              <li>Orange 4%</li>
              <li>Rtbr 4%</li>
              <li>Cream 9%</li>
              <li>Drank 7%</li>
            </ul>
            <h2>Secondary Flavors</h2>
            <ul>
              <li>None 1%</li>
              <li>Cherry 36%</li>
              <li>Vanilla 14%</li>
              <li>Mango 7%</li>
              <li>Coconut 7%</li>
              <li>Watermelon 5%</li>
              <li>Strawberry 4%</li>
              <li>Raspberry 4%</li>
              <li>Guava 3%</li>
              <li>Blueberry 3%</li>
              <li>Lychee 3%</li>
              <li>Pineapple 3%</li>
              <li>Passionfruit 3%</li>
              <li>Blackberry 2%</li>
              <li>Dragonfruit 2%</li>
              <li>Mangosteen 1%</li>
            </ul>
            <h2>Other Flavor Properties</h2>
            <ul>
              <li>Organic 1%</li>
              <li>Sour .5%</li>
              <li>Zero Sugar 10%</li>
              <li>Energy 5%</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
